<div class="appointment-details-panel" fusePerfectScrollbar *ngIf="appointment">
  <div class="header">
    <span class="title">
      {{ 'CALENDAR.SIDEBAR.TITLE' | translate }}
    </span>
    
    <mat-chip-list class="ml-12">
      <mat-chip [style]="{ background: appointment.status.color, color: secondaryColor }">
        {{ 'CALENDAR.SIDEBAR.' + appointment.status.name  | uppercase | translate }}
      </mat-chip>
    </mat-chip-list>

    <button mat-icon-button class="close-button" (click)="toggleSidebarOpen('AppointmentDetails')" style="margin-left: auto;">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form [formGroup]="form" *ngIf="form">
    <!-- CLIENT INFO -->
    <div class="group" *ngIf="appointment.client">
      <h2>{{ 'CALENDAR.SIDEBAR.CLIENT' | translate }}</h2>
      <div>
        <mat-icon>person</mat-icon>
        <ng-container *ngIf="canViewClient && appointment.client">
          <span class="client-name">
            <a [routerLink]="['/clients', appointment.client_id]" (click)="toggleSidebarOpen('AppointmentDetails')" >
              {{ appointment.client.first_name }} {{ appointment.client.last_name }}
            </a>
          </span>
        </ng-container>
        <ng-container *ngIf="!canViewClient && appointment.client">
          <span class="client-name">
            {{ appointment.client.first_name }} {{ appointment.client.last_name }}
          </span>
        </ng-container>
      </div>
      <div *ngIf="appointment.client">
        <span class="client-phone">{{ appointment.client.phone | phone }}</span>
      </div>
    </div>
  
    <!-- PROVIDER DETAILS -->
    <div class="group" *ngIf="appointment.provider">
      <h2>{{ 'CALENDAR.SIDEBAR.PROVIDER' | translate }}</h2>
      <div>
        <mat-icon>medical_services</mat-icon>
        
        <span class="client-name">
          {{ appointment.provider.first_name }} {{ appointment.provider.last_name }}
        </span>
      </div>
      <div *ngIf="appointment.provider">
        <span class="client-phone">{{ appointment.provider.phone | phone }}</span>
      </div>
      <ng-container *ngIf="appointment.provider.address">
        <div class="mt-12">
          <mat-icon>other_houses</mat-icon>
          <span class="appointment-text">{{ appointment.provider.address.street }}</span>
        </div>
        <div>
          <span class="appointment-subtext">{{ appointment.provider.address.suburb }} #{{ appointment.provider.address.exterior_number }}</span>
        </div>
      </ng-container>
    </div>

    <!-- APPOINTMENT DETAILS -->
    <div class="group">
      <h2>{{ 'CALENDAR.SIDEBAR.APPOINTMENT' | translate }}</h2>
      <div>
        <mat-icon>schedule</mat-icon>
        <span class="appointment-text">{{ duration }}</span>
      </div>
      <div>
        <span class="appointment-subtext">{{ appointment.start_date | dateFormat:'dddd, DD MMMM YYYY' }}</span>
      </div>
      <div class="mt-12" *ngIf="appointment.item && appointment.item.category">
        <mat-icon>business_center</mat-icon>
        <span class="appointment-text">{{ appointment.item.category?.name }}</span>
      </div>
      <div *ngIf="appointment.item">
        <span class="appointment-subtext">
          {{ appointment.item.name }} - 
          <span *ngIf="!appointment.item.coupon">{{ appointment.item.precio_venta | currency  }}</span>
          <span *ngIf="appointment.item.coupon" style="text-decoration: line-through;">
            {{ appointment.item.precio_venta  | currency  }}
          </span>
          <span *ngIf="appointment.item.coupon">
            &nbsp;{{ (appointment.item.precio_venta - (appointment.item.coupon.discount_type == 1 ? appointment.item.coupon.value : (appointment.item.precio_venta * (appointment.item.coupon.value / 100)))) | currency  }}
          </span>
        </span>
      </div>
      <ng-container *ngIf="appointment.office_id && appointment.office_id > 0">
        <div class="mt-12">
          <mat-icon>other_houses</mat-icon>
          <span class="appointment-text">{{ appointment.office.name }}</span>
        </div>
        <div fxLayout="column">
          <span class="appointment-subtext">{{ 'CALENDAR.SIDEBAR.IN_OFFICE' | translate }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="!(appointment.office_id && appointment.office_id > 0)">
        <div class="mt-12" *ngIf="appointment.item && appointment.item.office">
          <mat-icon>other_houses</mat-icon>
          <span class="appointment-text">{{ appointment.item.office.name }}</span>
        </div>
        <div fxLayout="column" *ngIf="appointment.item && (appointment.item.dispatchable || appointment.item.in_office)">
          <ng-container *ngIf="appointment.item.dispatchable && appointment.client">
            <span class="appointment-subtext">
              {{ 'CALENDAR.SIDEBAR.DISPATCHABLE' | translate }}
              <small *ngIf="appointment.item.address_query">- {{ appointment.item.address_query }}</small>
            </span>
            <img *ngIf="GoogleMap && appointment.item.address_latitude && appointment.item.address_longitude && appointment.item.category" class="appointment-subtext" src="https://maps.googleapis.com/maps/api/staticmap?center={{ appointment.item.address_latitude }},{{ appointment.item.address_longitude }}&markers=color:{{ appointment.item.category.color.replace('#', '0x') }}|label:{{ appointment.client.first_name[0] | uppercase }}|{{ appointment.item.address_latitude }},{{ appointment.item.address_longitude }}&zoom=15&size=500x100&key={{ GoogleMap }}" />
          </ng-container>
          <span class="appointment-subtext" *ngIf="appointment.item.in_office">{{ 'CALENDAR.SIDEBAR.IN_OFFICE' | translate }}</span>
        </div>
      </ng-container>
      <div class="mt-12" *ngIf="appointment.notes">
        <mat-icon>chat_bubble</mat-icon>
        <span class="appointment-text">{{ appointment.notes }}</span>
      </div>
    </div>

    <!-- ORDER -->
    <div class="group" *ngIf="appointment.order">
      <h2>
        {{ 'CALENDAR.SIDEBAR.ORDER' | translate }}
        <a [routerLink]="['/orders', appointment.order.uuid_key]" *ngIf="canViewOrder" (click)="toggleSidebarOpen('AppointmentDetails')">
          <mat-icon>shortcut</mat-icon>
        </a>
      </h2>
      <div>
        <mat-icon>person</mat-icon>
        <span class="client-name">{{ appointment.order.name }}</span>
      </div>
      <div>
        <span class="client-phone">{{ appointment.order.phone | phone }}</span>
      </div>
      <div class="mt-12">
        <mat-icon>credit_card</mat-icon>
        <span class="appointment-text">{{ appointment.order.method.name }} | <span [style.color]="appointment.order.status.color">{{ 'CALENDAR.SIDEBAR.' + appointment.order.status.name | translate }}</span></span>
      </div>
      <div *ngIf="appointment.order.total != appointment.order.subtotal">
        <span class="appointment-subtext">
          Subtotal {{ appointment.order.subtotal | currency  }}
        </span>
      </div>
      <div *ngIf="appointment.order.discount > 0">
        <span class="appointment-subtext">
          Descuento {{ appointment.order.discount | currency  }}
        </span>
      </div>
      <div>
        <span class="appointment-subtext">
          Total {{ appointment.order.total | currency  }}
        </span>
      </div>
      <ng-container *ngIf="appointment.order.coupons?.length > 0">
        <mat-divider class="my-16 mb-4 "></mat-divider>

        <div class="my-4" *ngFor="let _couponH of appointment.order.coupons">
          <mat-icon>confirmation_number</mat-icon>
          <span class="appointment-text">{{ _couponH.coupon?.name }}</span>
          <span class="ml-4 appointment-subtext">
             usado en {{ _couponH.type == 1 ? _couponH.item?.name : 'el carro de compras' }} - {{ _couponH.discount_type == 1 ? '$' : '%' }}{{ _couponH.value | currency:'':'' }}
          </span>
        </div>
        <div class="mb-4"></div>
      </ng-container>
      <div class="group" *ngIf="relatedAppointments?.length > 0">
        <h2><strong>{{ 'CALENDAR.SIDEBAR.RELATED_APPOINTMENTS' | translate }}</strong></h2>
        <div *ngFor="let app of relatedAppointments">
          <mat-icon>update</mat-icon>
          <span class="appointment-text">{{ app.start_date | dateFormat:'dddd, DD MMMM YYYY hh:mm a' }}</span>
        </div>
      </div>
    </div>

    <!-- APPOINTMENT STATUS -->
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>{{ 'CALENDAR.SIDEBAR.FORM.STATUS' | translate }}</mat-label>
      <mat-select formControlName="status_id" required>
        <mat-option value="">&nbsp;</mat-option>
        <mat-option [value]="s.id" *ngFor="let s of status">
          {{ 'CALENDAR.SIDEBAR.' + s.name  | uppercase | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- COMMENTS -->
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>{{ 'CALENDAR.SIDEBAR.FORM.COMMENTS' | translate }}</mat-label>
      <textarea matInput name="comments" formControlName="notes" rows="5" [maxLength]="150">
      </textarea>
      <mat-hint align="end">{{ (form.get('notes').value && form.get('notes').value.length) || 0}}/150</mat-hint>
    </mat-form-field>

    <ngx-spinner name="appointment_loading" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium" bdColor="rgba(51, 51, 51, 0.5)">
    </ngx-spinner>

    <mat-divider class="mb-16 mt-4"></mat-divider>
    
    <ngx-file-drop (onFileDrop)="dropped($event)" [showBrowseBtn]="true" (click)="fileUploadComponent.click()">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <mat-icon>backup</mat-icon>
        {{ 'MANAGEMENT.USERS.FILES.FILE_UPLOAD' | translate }}
      </ng-template>
    </ngx-file-drop>

    <input type="file" class="file_upload_component" #file accept="image/*,application/pdf,application/msword,audio/*,video/*,application/vnd.ms-powerpoint,application/vnd.ms-excel,text/*,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document" #fileUploadComponent (change)="fileChangeEvent($event)" multiple />

    <div *ngIf="UploadingFiles?.length > 0 || appointment.files.length > 0" class="processing-files pt-8">
      <div *ngFor="let uf of appointment.files" [@animate]="{value:'*',params:{y:'100%'}}">
        <span>{{ 'CALENDAR.SIDEBAR.FORM.ARCHIVED' | translate }} <b class="font-size-10">{{ uf.name }}</b></span>
      </div>
      <div *ngFor="let uf of UploadingFiles; let _i = index" [class.error]="uf.error" [@animate]="{value:'*',params:{y:'100%'}}">
        <span class="remove-file-label" (click)="deletePreFile(_i)">
          Click para eliminar <mat-icon>clear</mat-icon>
        </span>
        <span>{{ 'CALENDAR.SIDEBAR.FORM.WAITING_UPLOAD' | translate }} <b class="font-size-10">{{ uf.name }}</b></span>
        <span class="progress">
          <span *ngIf="uf.error" class="error-massage">{{ uf.error | translate }}</span>
          <span *ngIf="!uf.error" class="uploading-value">
            <span *ngIf="uf.progress < 100" class="font-size-9">{{ uf.posted_data | bytes : 2 }} / {{ uf.posted_total | bytes : 2 }} | </span>
            <span *ngIf="uf.progress >= 100" class="font-size-9">{{ uf.posted_total | bytes : 2 }} | </span>
            {{ uf.progress }}
          </span>
          <div class="value" [style.width]="uf.progress+'%'"></div>
        </span>
      </div>
    </div>

    <div style="display: flex;justify-content: space-between;" class="mt-24">
      <button mat-stroked-button class="mr-4" (click)="toggleSidebarOpen('AppointmentDetails')">
        <mat-icon>arrow_back</mat-icon>
        <span *ngIf="canEdit || canAdd">{{ 'CALENDAR.SIDEBAR.FORM.CANCEL' | translate }}</span>
        <span *ngIf="!(canEdit || canAdd)">{{ 'CALENDAR.SIDEBAR.FORM.BACK' | translate }}</span>
      </button>
      <button mat-raised-button color="accent" (click)="updateAppointment()" [disabled]="form.invalid" *ngIf="canEdit || canAdd">
        <mat-icon>save</mat-icon>
        {{ 'CALENDAR.SIDEBAR.FORM.UPDATE' | translate }}
      </button>
    </div>
  </form>
</div>