import { Component, HostBinding, OnDestroy, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppointmentsService } from 'app/core/services/appointments.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { UtilsService } from 'app/core/services/utils.service';
import { SettingsService } from 'app/core/services/settings.service';
import { StorageService } from 'app/core/services/storage.service';
import { Appointment, AppointmentStatus, Response, CouponHistory, User } from 'app/core/interfaces/all.interface';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

import { environment as env } from 'environments/environment';

import chroma from "chroma-js";
import * as moment from 'moment';

const MIN_CONTRAST_RATIO = 7, WHITE = chroma('white'), BLACK = chroma('black');

@Component({
  selector: 'appointment-details-sidebar',
  templateUrl: './appointment-details-sidebar.component.html',
  styleUrls: ['./appointment-details-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class AppointmentDetailsSidebarComponent implements OnInit, OnDestroy {
  appointment: Appointment;
  status: AppointmentStatus[];
  form: FormGroup;
  public files: NgxFileDropEntry[] = [];
  private _loadFile: Subject<any>;
  _loadedFiles: any[] = [];

  @HostBinding('class.bar-closed') barClosed: boolean;

  private _unsubscribeAll: Subject<any>;
  private _unsubscribeAppointment: any;
  private _unsubscribeUser: any;
  private _unsubscribeAppointmentStatus: any;

  get canView(): boolean {
    return this._utils.hasAccess('calendar/view');
  }

  get canEdit(): boolean {
    return this._utils.hasAccess('calendar/update');
  }

  get canDelete(): boolean {
    return this._utils.hasAccess('calendar/delete');
  }

  get canAdd(): boolean {
    return this._utils.hasAccess('calendar/create');
  }

  get canViewClient(): boolean {
    return this._utils.hasAccess('users/view') || this._utils.hasAccess('clients/view');
  }

  get canViewOrder(): boolean {
    return this._utils.hasAccess('orders/view');
  }

  get secondaryColor(): string {
    return this.generateColorPair().text;
  }

  get duration(): string {
    return `${moment(this.appointment.start_date, 'YYYY-MM-DD HH:mm:ss').format('hh:mm')}-${moment(this.appointment.end_date, 'YYYY-MM-DD HH:mm:ss').format('hh:mm a')}`;
  }

  get relatedAppointments(): Appointment[] {
    return this.appointment?.order.appointments.filter(a => a.id !== this.appointment.id);
  }

  get coupons(): CouponHistory[] {
    return this.appointment?.order.coupons.filter((a, b, s) => s.findIndex(f => f.code === a.code) != b);
  }
  
  get GoogleMap(): string {
    return this._settings.getCurrentSettings().google_api;
  }

  get UploadingFiles() {
    return this._loadedFiles;
  }

  constructor(
    private _appointments: AppointmentsService,
    private _formBuilder: FormBuilder,
    private _fuseSidebarService: FuseSidebarService,
    private _utils: UtilsService,
    private _storage: StorageService,
    private _settings: SettingsService,
  ) {
    this._loadFile = new Subject();
    // Set the defaults
    this.barClosed = true;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      notes: this._formBuilder.control({ value: '', disabled: (!this.canEdit) }),
      status_id: this._formBuilder.control({ value: '', disabled: (!this.canEdit) })
    });

    this._unsubscribeAppointment = this._appointments.onCurrentAppointmentChanged().subscribe((appointment: Appointment) => {
      if (appointment) {
        this.appointment = appointment;
        this._loadedFiles = [];
        this.form = this._formBuilder.group({
          notes: this._formBuilder.control({ value: appointment.notes, disabled: (!this.canEdit) }),
          status_id: this._formBuilder.control({ value: appointment.status_id, disabled: (!this.canEdit) })
        });
      } else {
        this.appointment = null;
      }
    });

    this._unsubscribeUser = this._storage.onCurrentUserChanged().subscribe((user: User) => {
      if (user) {
        this._unsubscribeAppointmentStatus = this._appointments.status().subscribe((response: Response<AppointmentStatus[]>) => {
          this.status = response.data;
        });
      }
    });

    this._loadFile.subscribe((files) => {
      [...files].forEach((f) => {
        f.ready_posted = false;
        f.posting = true;
        
        this._loadedFiles.push(f);
        if (this.validateFileType(f) && this.validateFileSize(f.size)) {
        } else if (!this.validateFileType(f)) {
          f.error = "MANAGEMENT.USERS.FILES.ERRORS.WRONG_TYPE";
          setTimeout(() => {
            f.ready_posted = true;
          }, 20000);
        } else if (!this.validateFileSize(f.size)) {
          f.error = "MANAGEMENT.USERS.FILES.ERRORS.WRONG_SIZE";
          setTimeout(() => {
            f.ready_posted = true;
          }, 20000);
        } else {
          f.error = "MANAGEMENT.USERS.FILES.ERRORS.WRONG_TYPE";
          setTimeout(() => {
            f.ready_posted = true;
          }, 20000);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAppointment?.unsubscribe();
    this._unsubscribeUser?.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  generateColorPair() {
    let bg = null, text = null;
    
    bg = chroma(this.appointment?.status.color);
    let contrastWithWhite = chroma.contrast(bg, WHITE),
        contrastWithBlack = chroma.contrast(bg, BLACK);
    if (contrastWithWhite <= contrastWithBlack) {
      text = WHITE;
    } else {
      text = BLACK;
    }
    
    let contrast = parseFloat(chroma.contrast(bg, text).toFixed(1));
    return { bg: bg.hex(), text: text.hex(), contrast };
  }

  updateAppointment() {
    this._utils.showLoading('appointment_loading');
    this._appointments.update({...this.form.value, ...{
      id: this.appointment.id,
      start_date: this.appointment.start_date,
      end_date: this.appointment.end_date,
      files: this.appointment.files
    }}, this._loadedFiles).subscribe((response: Response<Appointment>) => {
      if (this.appointment.files) {
        this.appointment.files = this.appointment.files.concat([...this._loadedFiles])
      } else {
        this.appointment.files = [...this._loadedFiles];
      }
      this._loadedFiles = [];
      this._utils.hideLoading('appointment_loading');
    }, (error) => {
      this._utils.hideLoading('appointment_loading');
    });
  }

  dropped(files: NgxFileDropEntry[]) {
    let _files = [];
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          _files.push(file);
          if (files.length == _files.length) {
            this._loadFile.next(_files);
          }
        });
      }
    }
  }

  fileChangeEvent(event) {
    this._loadFile.next([...event.srcElement.files]);
  }

  validateFileType(file) {
    const valid_extensions = ['.docx', '.jpg', '.jpng', '.png', '.pdf', '.doc', '.ppt', '.pptx', '.csv', '.xls', '.xlsx', '.png', '.gif', '.tiff', '.bmp', '.mp3', '.acc', '.wma', '.json', '.avi'];
    const extension = file.name.slice((file.name.lastIndexOf(".") - 2 >>> 0) + 2);
    return file.type.indexOf('application/pdf') != -1 || 
      file.type.indexOf('application/msword') != -1 ||
      file.type.indexOf('application/vnd.ms-powerpoint') != -1 ||
      file.type.indexOf('application/vnd.ms-excel') != -1 ||
      file.type.indexOf('application/vnd.openxmlformats-officedocument.presentationml.presentation') != -1 ||
      file.type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') != -1 ||
      file.type.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') != -1 ||
      file.type.indexOf('image/') != -1 ||
      file.type.indexOf('audio/') != -1 ||
      file.type.indexOf('video/') != -1 || 
      file.type.indexOf('text/') != -1 ||
      valid_extensions.includes(extension);
  }

  validateFileSize(size) {
    return size <= 5242880;
  }

  deletePreFile(index) {
    this._loadedFiles.splice(index, 1);
  }
}
