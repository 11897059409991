import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { StorageService } from "../services/storage.service";

import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private _storage: StorageService, 
    private router: Router, 
    private _notifier: NotifierService,
    private _translate: TranslateService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: String = this._storage.getCurrentToken();

    let request = req;

    if (token && !req.headers.get('Authorization')) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if ([401, 0].includes(+err.status)) {
          this._storage.removeCurrentSession();
          this.router.navigateByUrl('/auth/login');
        } else {
          let _message = ((err.error && err.error.message) || err.statusText || '').toUpperCase();
          if (err.error && err.error.error) {
            let _notify = true;
            for(let e in err.error.error) {
              if (Array.isArray(err.error.error[e])) {
                err.error.error[e].forEach((m, mi) => {
                  if (m instanceof Object) {
                    for(let m1 in m) {
                      _notify = false;
                      this._notifier.notify('error', `${e}: ${m1} - ${m[m1]}`);
                    }
                  } else {
                    _notify = false;
                    this._notifier.notify('error', this._translate.instant(`ERRORS.${e}.${m}`.toUpperCase(), e));
                  }
                });
              } else {
                _notify = false;
                this._notifier.notify('error',  this._translate.instant(`ERRORS.${_message}`, err.error.error));
              }
            }
            if (_notify) {
              this._notifier.notify('error', this._translate.instant(`ERRORS.${_message}`));
            }
          } else {
            this._notifier.notify('error', this._translate.instant(`ERRORS.${_message}`));
          }
        }

        return throwError( err );
      })
    );
  }
}
