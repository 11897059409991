import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseURLs',
  pure: false
})
export class ParseUrlsPipe implements PipeTransform {
  private linkExp = /((s?ftp|https?):\/\/|(www\.)|(mailto:)?[A-Za-z0-9._%+-]+@)\S*[^\s.;,(){}<>"\u201d\u2019]/i;
  private mailExp = /^mailto:/i;
  transform(value: string, isHTML: boolean = false , options: any = {}): any {
    if (typeof value !== 'string') { return null; }
    if (value === null || value === '') { return value; }
    let rawText = value;
    const html = [];
    const URLS = [];
    let match;
    let i;
    let url;
    // eslint-disable-next-line no-cond-assign
    while ((match = rawText.match(this.linkExp))) {
      [url] = match;
      if (!match[2] && !match[4]) {
        url = (match[3] ? 'http://' : 'mailto:') + url;
      }
      i = match.index;
      if (rawText.substr(0, i)) {
        html.push(rawText.substr(0, i));
      }
      html.push('<a ');
      if (options) {
        Object.keys(options).forEach((key) => {
          html.push(`${key}="${options[key]}" `);
        });
      }
      html.push('href="');
      URLS.push(url.replace(/"/g, '&quot;'));
      html.push(url.replace(/"/g, '&quot;'));
      html.push('">');

      if (match[0].replace(this.mailExp, '')) {
        html.push(match[0].replace(this.mailExp, ''));
      }
      html.push('</a>');
      rawText = rawText.substring(i + match[0].length);
    }
    if (rawText) {
      html.push(rawText);
    }
    return isHTML ? html.join('') : URLS;
  };
}