import { Component, HostBinding, OnDestroy, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormBuilder, FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProductsImportService } from 'app/core/services/productsImport.service';
import { CategoriesService } from 'app/core/services/categories.service';
import { ApplicationTypesService } from 'app/core/services/applicationTypes.service';
import { PriceListService } from 'app/core/services/priceLists.service';
import { StorageService } from 'app/core/services/storage.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { UtilsService } from 'app/core/services/utils.service';
import { Response } from 'app/core/interfaces/all.interface';
import * as moment from 'moment';

export class CategoryMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return form.invalid && form.hasError('category_empty');
  }
}

export class ApplicationTypeMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return form.invalid && form.hasError('application_empty');
  }
}

export class PriceMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return form.invalid && +(`${control.value}`.replace(/[^\d\.]/, '')) <= 0;
  }
}

@Component({
  selector: 'product-details-sidebar',
  templateUrl: './product-details-sidebar.component.html',
  styleUrls: ['./product-details-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ProductDetailsSidebarComponent implements OnInit, OnDestroy {
  product: any;
  form: FormGroup;

  price_list: any[] = [];
  categories: any[] = [];
  application_types: any[] = [];

  application_type_matcher = new ApplicationTypeMatcher();
  category_matcher = new CategoryMatcher();
  price_matcher = new PriceMatcher();

  @HostBinding('class.bar-closed') barClosed: boolean;

  private _unsubscribeAll: Subject<any>;
  private _unsubscribeProducts: any;

  get canView(): boolean {
    return this._utils.hasAccess('products/view');
  }

  get canEdit(): boolean {
    return this._utils.hasAccess('products/update');
  }

  get canDelete(): boolean {
    return this._utils.hasAccess('products/delete');
  }

  get canAdd(): boolean {
    return this._utils.hasAccess('products/create');
  }

  constructor(
    private _productsImport: ProductsImportService,
    private _formBuilder: FormBuilder,
    private _pricelist: PriceListService,
    private _categories: CategoriesService,
    private _applicationTypes: ApplicationTypesService,
    private _fuseSidebarService: FuseSidebarService,
    private _utils: UtilsService,
    private _storage: StorageService,
  ) {
    // Set the defaults
    this.barClosed = true;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    this.form = this._formBuilder.group({
      notes: this._formBuilder.control({ value: '', disabled: (!this.canEdit) }),
      status_id: this._formBuilder.control({ value: '', disabled: (!this.canEdit) })
    });

    if (this._storage.isAuthenticated()) {
      forkJoin({
        priceList: this._pricelist.list(),
        categories: this._categories.list({ active: 'all' }),
        application_types: this._applicationTypes.all()
      }).subscribe((response: { priceList, categories, application_types }) => {
        this.price_list = response.priceList.data.map(p => Object({ column: `price_list_${p.id}`, name: p.name, id: p.id }));

        this.categories = response.categories.data.map(c => Object({...c, ...{ parse: c.name?.toLowerCase() }}));
        this.application_types = response.application_types.data.map(a => Object({...a, ...{ parse: a.name?.toLowerCase() }}));
      });
    }

    this._unsubscribeProducts = this._productsImport.onCurrentProductChanged().subscribe((product: any) => {
      if (product) {
        let _tmp: any = {};
        for(let p in product) {
          _tmp[p] = [{
            value: product[p],
            disabled: !(this.canEdit || (this.canAdd))
          }];
        }

        _tmp['category'] = [{
          value: _tmp['category'] && _tmp['category'][0].value.toLowerCase(),
          disabled: !(this.canEdit || (this.canAdd))
        }];
        _tmp['application_type'] = [{
          value: _tmp['application_type'] && _tmp['application_type'][0].value.toLowerCase(),
          disabled: !(this.canEdit || (this.canAdd))
        }];

        let _default = {
          'sku': '', 
          'barcode': '', 
          'name': '', 
          'description': '', 
          'short_description': '', 
          'type': '', 
          'precio_base': '', 
          'precio_venta': '', 
          'precio_promocion': '', 
          'precio_renta': '', 
          'category': '', 
          'application_type': '', 
          'dispatchable': false, 
          'in_office': false, 
          'tags': '', 
          'active': false, 
          'requirements': '',
          'sat_clave': '', 
          'sat_unidad': '', 
          'sat_concepto': '', 
          'sat_concepto_unidad': ''
        };

        this.price_list.forEach(p => {
          _default[p.column] = '';
          _default[p.column+'_iva'] = '';
        });

        Object.keys(_default).forEach(d => {
          if (!_tmp[d]) {
            _tmp[d] = [{
              value: _default[d],
              disabled: !(this.canEdit || (this.canAdd))
            }];
          }
        });

        this.form = this._formBuilder.group(_tmp, { validator: this.formValidations(this.categories, this.application_types) });
      }
      this.product = product;
    });
  }

  formValidations(categories: any[], application_types: any[]) {
    return (group: FormGroup) => {
      const _dispatchable = group.get('dispatchable')?.value || false;
      const _in_office = group.get('in_office')?.value || false;
      const _category = group.get('category')?.value || false;
      const _application = group.get('application_type')?.value || false;
      const _price_base = group.get('precio_base')?.value || 0;
      const _price_venta = group.get('precio_venta')?.value || 0;
      const _errors: any = {};

      if (!(_dispatchable || _in_office)) { 
        _errors.sendMethodError = true 
      }

      if (!_category || categories.length <= 0 || !categories.map(c => c.parse).includes(_category)) {
        _errors.category_empty = true;
      }

      if (!_application || application_types.length <= 0 || !application_types.map(a => a.parse).includes(_application)) {
        _errors.application_empty = true;
      }

      if (_price_base <= 0 || _price_venta <= 0) {
        _errors.price_zero = true;
      }

      return _errors;
    };
  }

  ngOnDestroy(): void {
    this._unsubscribeProducts?.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  updateRecord() {
    let _data = this.form.getRawValue();

    _data['category'] = this.categories.find(c => c.parse === _data['category']).name;
    _data['application_type'] = this.application_types.find(a => a.parse === _data['application_type']).name;

    this._productsImport.save(_data);
  }
}
