import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';

import { AuthenticationService } from 'app/core/services/authentication.service';
import { StorageService } from 'app/core/services/storage.service';
import { NotificationsService } from 'app/core/services/notificationes.service';
import { UtilsService } from 'app/core/services/utils.service';
import { User, Notification } from 'app/core/interfaces/all.interface';
import { FuseNavigation } from '@fuse/types';

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})

export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  showShortcut: boolean = false;
  notifications: Notification[];
  hasNewNotifications: boolean = false;

  // Private
  private _unsubscribeAll: Subject<any>;
  private _unsubscribeUser: any;

  get User(): User {
    return this._storage.getCurrentUser() || ({} as User);
  }

  get UnreadNotifications(): number {
    return this._notifications?.getUnreadNotifications().filter(n => !n.deleted_at).length;
  }

  get AllNotifications(): Notification[] {
    return this._notifications?.getNotifications().filter(n => !n.deleted_at).sort(function compare(a, b) {
      var dateA: any = new Date(a.created_at);
      var dateB: any = new Date(b.created_at);
      return dateB - dateA;
    });
  }

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _service: AuthenticationService,
    private _storage: StorageService,
    private _translateService: TranslateService,
    private _notifications: NotificationsService,
    private _utils: UtilsService,
    private router: Router
  ) {
    // Set the defaults
    this.userStatusOptions = [{
      title: 'Online',
      icon : 'icon-checkbox-marked-circle',
      color: '#4CAF50'
    }, {
      title: 'Away',
      icon : 'icon-clock',
      color: '#FFC107'
    }, {
      title: 'Do not Disturb',
      icon : 'icon-minus-circle',
      color: '#F44336'
    }, {
      title: 'Invisible',
      icon : 'icon-checkbox-blank-circle-outline',
      color: '#BDBDBD'
    }, {
      title: 'Offline',
      icon : 'icon-checkbox-blank-circle-outline',
      color: '#616161'
    }];

    this.languages = [{
      id   : 'en',
      title: 'English',
      language: 'en-US',
      flag : 'us'
    }, {
      id   : 'tr',
      title: 'Turkish',
      language: 'tr',
      flag : 'tr'
    }, {
      id   : 'mx',
      title: 'Español',
      language: 'es-mx',
      flag : 'mx'
    }];

    // this.clearMenu(navigation);
    // Get default navigation
    this.navigation = [];
    this.showShortcut = false;
    this._unsubscribeUser = this._storage.onCurrentUserChanged().subscribe((user: User) => {
      if (user) {
        // this.clearMenu(navigation);
        // Get default navigation
        this.navigation = this.validMenu(navigation, user)?.filter(f => !f.hidden);

        this.showShortcut = this.navigation?.length > 0;
      }
    })


    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    this._notifications.onNotificationsChanged()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: Notification[]) => {
        this.notifications = notifications;
        if (this.UnreadNotifications > 0) {
          this.hasNewNotifications = true;
        }
      })
    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._unsubscribeUser.unsubscribe();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;
    moment.locale(lang?.language || 'es');
    // Use the selected language for translations
    this._translateService.use(this._storage.storageLanguege(lang.id) || 'mx');
  }

  logout(): void {
    this._utils.showLoading();
    this._service.logout().subscribe((response) => {
      this._storage.removeCurrentSession();
      this._utils.hideLoading();
      this.router.navigateByUrl('/auth/login');
    })
  }

  validMenu(nav: FuseNavigation[], user: User): FuseNavigation[] {
    let _nav = nav;
    let _listPermission = user.permisos.filter(p => +p.access === 1).map(p => `${p.controller}/${p.action}`);

    nav.forEach((n, i) => {
      if (n.type === 'item') {
        if (!n.auth) n.hidden = true;
        if (!n.auth.find(f => _listPermission.includes(f)) && !n.auth.includes('guest')) n.hidden = true;
        else if (n.is_sucursal && !user.role.is_sucursal) n.hidden = true;
        else n.hidden = false;
      }
      if (n.children && n.children.length > 0) {
        this.validMenu(n.children, user);
        if (n.children.filter(c => !c.hidden).length <= 0) n.hidden = true;
      }
    });
    return _nav;
  }

  clearMenu(nav: FuseNavigation[]): FuseNavigation[] {
    let _nav = nav;

    nav.forEach((n, i) => {
      if (n.type === 'item') {
        delete n.hidden;
      }
      if (n.children && n.children.length > 0) {
        this.clearMenu(n.children);
        delete n.hidden;
      }
    });
    return _nav;
  }

  markAllAsRead() {
    this._notifications?.getUnreadNotifications().forEach((n) => {
      n.readed = true;
    });

    this._notifications.readAll().subscribe(() => {
      let _everyoneNotifications: Notification[] = _(this._notifications.getEveryoneNotifications()).filter(f => f.user_id == null).map((m) => {
        return { id: m.id, readed: true, deleted_at: m.deleted_at } as Notification;
      }).value();
        
      localStorage.setItem(`notifications.${this.User.id}`, JSON.stringify(_everyoneNotifications));
    });
  }

  closePanel() {

  }

  toggleRead(notification: Notification) {
    notification.readed = !notification.readed;
    if (notification.user_id) {
      this._notifications.update(notification).subscribe(() => {
        
      });
    } else {
      let _everyoneNotifications: Notification[] = JSON.parse(localStorage.getItem(`notifications.${this.User.id}`) || '[]') as Notification[];
      let _found = _.find(_everyoneNotifications, {
        id: notification.id
      });

      if (_found) {
        _found.readed = !_found.readed;
        localStorage.setItem(`notifications.${this.User.id}`, JSON.stringify(_everyoneNotifications));
      } else {
        _everyoneNotifications.push({ id: notification.id, readed: notification.readed, deleted_at: notification.deleted_at } as Notification);
        localStorage.setItem(`notifications.${this.User.id}`, JSON.stringify(_everyoneNotifications));
      }
    }
  }

  delete(notification: Notification, event) {
    notification.on_process_delete = !notification.on_process_delete;
    event.stopPropagation();
  }

  performDelete(notification: Notification, event) {
    this._utils.showLoading('delete_notification' + notification.id);
    if (notification.user_id) {
      this._notifications.delete(notification).subscribe(() => {
        notification.on_process_delete = !notification.on_process_delete;
        this._utils.hideLoading('delete_notification' + notification.id);
      });
    } else {
      notification.on_process_delete = !notification.on_process_delete;
      this._utils.hideLoading('delete_notification' + notification.id);
      let _everyoneNotifications: Notification[] = JSON.parse(localStorage.getItem(`notifications.${this.User.id}`) || '[]') as Notification[];
      let _found = _.find(_everyoneNotifications, {
        id: notification.id
      });

      if (_found) {
        _found.deleted_at = notification.deleted_at = new Date();
        localStorage.setItem(`notifications.${this.User.id}`, JSON.stringify(_everyoneNotifications));
      } else {
        notification.deleted_at = new Date();
        _everyoneNotifications.push({ id: notification.id, readed: notification.readed, deleted_at: notification.deleted_at } as Notification);
        localStorage.setItem(`notifications.${this.User.id}`, JSON.stringify(_everyoneNotifications));
      }
    }
    event.stopPropagation();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
